<template>
  <div class="lg:w-2/3">
    <div class="vx-row">
      <vs-input
        v-validate="'required|email'"
        data-vv-validate-on="blur"
        name="email"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        label-placeholder="Email"
        :placeholder="$t('Email')"
        v-model="email"
        class="w-full"
      />
      <span class="text-danger text-sm">{{ errors.first("email") }}</span>
    </div>
    <div class="vx-row mt-2">
      <vs-input
        data-vv-validate-on="blur"
        v-validate="'required|min:6'"
        type="password"
        name="password"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        label-placeholder="Password"
        :placeholder="$t('Password')"
        v-model="password"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("password") }}</span>
    </div>

    <div class="flex flex-wrap justify-between my-5">
      <vs-checkbox v-model="checkbox_remember_me" class="text-sm mb-3">{{
        $t("RememberMe")
      }}</vs-checkbox>

      <router-link to="/pages/ForgotPassword">{{
        $t("ForgotPassword")
      }}</router-link>

      <!-- <span @click="ForgotPassword">{{ $t("ForgotPassword") }}</span> -->
    </div>
    <div class="flex flex-wrap mb-3 float-right">
      <vs-button color="primary" :disabled="!validateForm" @click="loginJWT">{{
        $t("Login")
      }}</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      checkbox_remember_me: false
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    }
  },
  methods: {
    ForgotPassword() {
      this.$router.push("/pages/ForgotPassword");
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: this.$t("LoginAttempt"),
          text: this.$t("Youarealreadyloggedin"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });

        return false;
      }
      return true;
    },
    loginJWT() {
      // debugger;
      if (!this.checkLogin()) return;
      // Loading
      this.$vs.loading();

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password,
          Language: "English"
        }
      };

      this.$store
        .dispatch("auth/loginJWT", payload)
        .then(res => {
          if(res.data.StatusCode==201){
             this.$vs.loading.close();

             this.$vs.notify({
            title: this.$t("Error"),
            text: res.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });

          }
          else{
             this.$vs.loading.close();

          debugger;
          if (res.data.userData.UserType == "Admin") {
            this.$router.push("/").then(() => {
              location.reload("/");
            });
          } else {
            this.$router
              .push(this.$router.currentRoute.query.to || "/")
              .then(() => {
                location.reload("/");
              });
          }
          }
         
        })
        .catch(() => {
          // if (Error.response.status == 400) {
          //   this.$vs.notify({
          //     title: this.$t("Error"),
          //     text: Error.response.data.message,
          //     iconPack: "feather",
          //     icon: "icon-alert-circle",
          //     color: "danger"
          //   });
          // } else {
          this.$vs.notify({
            title: this.$t("Error"),
            text: "ERROR",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
          // }
          this.$vs.loading.close();
        });
    }
  }
};
</script>
