<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-full  flex w-full vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col w-full sm:m-0 ">
      <div slot="no-body" class="full-page-bg-color bg-white">
        <div class="vx-row no-gutter justify-center items-center">
          <div class="hidden lg:w-1/2">
          <!-- <div class="hidden lg:block lg:w-1/2"> -->

            <div class="z-30   inset-y-0 left-0">
              <img
                src="@/assets/images/pages/login.jpg"
                alt="login"
                class="w-full bg-local"
              />
            </div>
          </div>
          <div class="vx-col  sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
            <div class="lg:ml-24 lg:mt-24 px-8 pt-8 login-tabs-container">
              <div class="lg:w-3/5 items-center justify-start ">
                <logo class="w-full fill-current text-primary mt-6 mb-2" />
              </div>
              <div class="vx-card__title mb-4 pt-4">
                <h4 class="mb-4 font-bold text-md">{{ $t("Login") }}</h4>
                <p class="text-danger text-sm font-bold">
                  {{ $t("WelcomeBack") }}
                </p>
              </div>

              <login-jwt></login-jwt>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginJwt from "./LoginJWT.vue";
// import Logo from "@/layouts/components/Logo.vue";

export default {
  components: {
    LoginJwt,
    // Logo
  }
};
</script>

<style lang="scss">
.login-tabs-container {
  height: calc(100vh - 120px);
  min-height: calc(100vh - 220px);

  .con-tab {
    padding-bottom: 14px;
  }
  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
